import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      query {
        empty: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "empty" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }`}
    render={data => (
      <Layout>
        <BackgroundImage fluid={data.empty.childImageSharp.fluid}>
          <NotFoundContainer>
            <h1>KHÔNG TÌM THẤY</h1>
            <p>Có vẻ như bạn vừa đi lạc, quay về <HomeLink to='/'>Trang Chủ</HomeLink></p> 
          </NotFoundContainer>
        </BackgroundImage>
      </Layout>
    )}
  />
);

const NotFoundContainer = styled(Container)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const HomeLink = styled(Link) `
  text-decoration: capitailize;
  color: #2b2b2b;
  &:hover {
    color: black;
  }
`
export default NotFoundPage;
