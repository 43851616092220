import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const Section = styled.section`
  display: flex;
  padding: 0 6rem;

  ${props =>
    props.accent &&
    `background-color: ${
      props.accent === 'secondary'
        ? '#f4f4f4'
        : props.theme.color.white.regular
    }`};

  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 2rem;
  }
`;
